import { Controller } from "stimulus";

export default class extends Controller {
  //
  // life cycle
  //
  initialize() {
    $('div[data-split]').each((i, el) => {
      this.charSplitBySpan(el);
    });
    $(window).on('scroll', () => {
      $('div[data-parallax]').each((i, el) => {
        this.parallax(el);
      });
    });
    $(window).on('scroll', () => {
      $('div[data-animation-in]').each((i, el) => {
        this.animationIn(el);
      });
    });
  }

  //
  // target
  //
  static targets = [
  ]

  //
  // article
  //
  charSplitBySpan(el) {
    const $target = $(el);
    const text = $target.text();
    if (text === '') return;
    $target.text('');
    text.split('').forEach((char, i) => {
      if (char === ' ') {
        char = "&nbsp;";
      }
      const $span = $(`<span class="char" style="--char-index:${i};">${char}</span>`);
      $target.append($span);
    });
  }
  //
  // パララックス
  //
  parallax(el) {
    const target = $(el);
    const targetY = target.offset().top;
    const scrollY = $(window).scrollTop();
    const windowHeight = $(window).height();

    const ratio = 0.15;
    const startY = targetY - windowHeight;
    if (scrollY > startY) {
      target.css('background-position-y', (scrollY - targetY) * ratio + 'px');
    } else {
      // target.css('background-position','center top');
    }
  };

  //
  // 画面に入ってきたらアニメーションをスタートする
  //
  animationIn(el) {
    const animationCssName = 'animation-in';

    const target = $(el);
    const targetY = target.offset().top;
    const scrollY = $(window).scrollTop();
    const windowHeight = $(window).height();

    const bottom = 0;
    const startY = targetY - windowHeight - bottom;
    if (scrollY > startY) {
      if (!target.hasClass(animationCssName)) {
        target.addClass(animationCssName);
      }
    }
  };
};

