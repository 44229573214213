import { Controller } from "stimulus";

let scrollX = 10;

export default class extends Controller {
  //
  // life cycle
  //
  initialize() {
  }

  //
  // target
  //
  static targets = [
    'header',
    'body',
    'menu',
    'openIcon',
    'closeIcon'
  ]

  //
  // article
  //
  show() {
    scrollX = $('html, body').scrollTop();
    // すぐにボタンを閉じて、ボディのopacityが0に向かう
    $(this.openIconTarget).addClass('is-active');
    $(this.bodyTarget).addClass('fade');
    setTimeout(() => {
      $(this.headerTarget).addClass('style-active');
      $(this.bodyTarget).addClass('hidden');
      $('html, body').scrollTop(0);
    }, 150);
    setTimeout(() => {
      $(this.closeIconTarget).removeClass('is-inactive');
      $(this.menuTarget).removeClass('fade');
    }, 200);
  }
  hide() {
    $(this.closeIconTarget).addClass('is-inactive');
    $(this.menuTarget).addClass('fade');

    setTimeout(() => {
      // 表示とアニメーションをずらすには、setTimeoutをいれておくだけでOK
      $(this.headerTarget).removeClass('style-active');
      $(this.bodyTarget).removeClass('hidden');
    }, 150);
    setTimeout(() => {
      $(this.openIconTarget).removeClass('is-active');
      $(this.bodyTarget).removeClass('fade');
      $('html, body').scrollTop(scrollX);
    }, 200);
  }
};

